import React from "react";
import styled from "styled-components";
import Card from "./Card";
import { AiOutlineArrowUp } from "react-icons/ai";

import { Slide, Fade } from "react-awesome-reveal";

const Clients = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Container id="client">
      <Slide direction="down">
        <h4>
          <span className="blue"> Mis </span>
          <span className="green">Clientes</span>
        </h4>
        <h1>
          <span className="blue"> Empresas que confían en las soluciones</span>{" "}
          <span className="green">VisualContab</span>
        </h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={
              "https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/chacorelogo.png"
            }
            title={"Sistema Administrativo Contable"}
            disc={`Ventas de Materiales para la construccion - www.chacore.com.py`}
          />
        </Slide>
        <Slide direction="down">
          <Card
            Icon={
              "https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/activa_1.png"
            }
            title={"Sistema Contable, apps móviles"}
            disc={`ACTIVA es parte de la empresa PROGEN SRL que Ofrece la gestión contable - www.activa.com.py`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={
              "https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/descarga.jpg"
            }
            title={"Sistema Administrativo Contable"}
            disc={`Chacore Concretos empresa de ventas de hormigon elevorado - www.chacore.com.py`}
          />
        </Slide>
      </Cards>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={
              "https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/systemlogo.png"
            }
            title={"Sistema Contable"}
            disc={`Industria Farmaceutica,  Laboratorio de Medicamentos  Email: admgroup.py@gmail.com`}
          />
        </Slide>
        <Slide direction="up">
          <Card
            Icon={
              "https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/meyerlogo.png"
            }
            title={"Sistema Contable"}
            disc={`Laboratorio de Analisis Clinicos - Biologico Molecular  www.meyerlab.com.py`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={
              "https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/dutriec_7.png"
            }
            title={"Sistema Contable"}
            disc={`Industria Farmaceutica, laboratorio de medicamentos wwww.dutriec.com.py`}
          />
        </Slide>
      </Cards>
      <Fade>
        <ArrowUp onClick={scrollUp}>
          <AiOutlineArrowUp />
        </ArrowUp>
      </Fade>
    </Container>
  );
};

export default Clients;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
const ArrowUp = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #01be96;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: 650px) {
    position: absolute;
    right: 3rem;
    top: 16rem;
  }
`;
