import React from "react";
import styled from "styled-components";
import { AiOutlineArrowUp } from "react-icons/ai";
import { Slide, Fade } from "react-awesome-reveal";

const Projects = () => {
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Container id="project">
      <Slide direction="down">
        <h1>
          <span className="green">
            La solución online perfecta Para tu Empresa{" "}
          </span>
        </h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Profile>
            <img
              src="https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/visualcontab9.png"
              alt="profile"
            />
          </Profile>
        </Slide>

        <Slide direction="down">
          <Profile>
            <img
              src="https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/visualcontab7.png"
              alt="profile"
            />
          </Profile>
        </Slide>

        <Slide direction="right">
          <Profile>
            <img
              src="https://yvqilxcqdcygpumctnxu.supabase.co/storage/v1/object/public/%20cabin-images/visualcontab5.png"
              alt="profile"
            />
          </Profile>
        </Slide>
      </Cards>
      <Fade>
        <ArrowUp onClick={scrollUp}>
          <AiOutlineArrowUp />
        </ArrowUp>
      </Fade>
    </Container>
  );
};

export default Projects;

const Container = styled.div`
  gap: 2rem;
  padding-top: 3rem;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
  @media (max-width: 840px) {
    width: 90%;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const Profile = styled.div`
  img {
    width: 25rem;
    filter: drop-shadow(0px 10px 10px #01be9570);
    transition: transform 400ms ease-in-out;
    @media (max-width: 790px) {
      width: 20rem;
    }

    @media (max-width: 660px) {
      width: 18rem;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  :hover img {
    transform: translateY(-10px);
  }
`;

const ArrowUp = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #01be96;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  @media (max-width: 650px) {
    position: absolute;
    right: 3rem;
    top: 16rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
