import React from "react";
import { FiCodesandbox } from "react-icons/fi";
import { IoCodeSlashSharp } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h4>
        <span className="blue"> Mis</span> <span className="green">servicios</span>
        </h4>
        <h1><span className="blue"> Lo que hago</span></h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={IoCodeSlashSharp}
            title={"Desarrollo de Software a Medida"}
            disc={`Impulsamos y transformamos empresas utilizando tecnologías de vanguardia.   
                 Pensamos, creamos, innovamos.
                 Con dinámico, con sólida experiencia en desarrollo de software y junto a nuestros clientes resolvemos las necesidades y cumplimos los objetivos de sus empresas.`}
          />
        </Slide>
        <Slide direction="down">
          <Card
            Icon={FiCodesandbox}
            title={"Desarrollo apps móviles"}
            disc={`Diseñamos y desarrollamos aplicaciones móviles que resuelven los problemas de tu negocio, atraen clientes y posicionan tu marca en el mercado.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={CgWebsite}
            title={"Consultoría Informática"}
            disc={`Una buena elección y asesoramiento sobre los sistemas y herramientas informáticas que han de dar soporte a una organización es esencial para que ésta se mantenga en constante rendimiento`}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
